import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  width: 100%;
  height: 100%;
  .table-webhook-container {
    display: block;
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    color: white;
    .table-column-method {
      width: 15%;
    }
    .table-column-type {
      width: 25%;
    }
    .table-column-url {
      width: 40%;
    }
    .table-column-option {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      .option-btn-container {
        width: 18px;
        height: 18px;
        cursor: pointer;
        margin: 0 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .table-url-camera-header {
      display: block;
      background: #242424;
      tr {
        display: flex;
        th {
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 8px;
          &.table-column-method {
            display: flex;
            justify-content: start;
            padding-left: 40px;
          }
        }
      }
    }
    .table-url-camera-body {
      display: block;
      height: 256px;
      overflow-y: overlay;
      padding: 8px 0;
      background: #1a1a1a;
      tr {
        display: flex;
        td {
          padding: 8px;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &.table-column-method {
            display: flex;
            justify-content: start;
            padding-left: 40px;
          }
          &.table-column-option {
            overflow: visible;
            white-space: normal;
            text-overflow: clip;
            .option-btn {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 26px;
              height: 26px;
              margin: 0 auto;
              cursor: pointer;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
              .option-wrapper {
                right: 0px;
                top: 30px;
              }
            }
          }
        }
      }
      .no-data-row-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 180px;
        .no-data-content {
          display: flex;
          padding: 0px;
        }
      }
    }
  }
  @media only screen and (max-width: ${device.maxSizeS}) {
    .table-webhook-container {
      .table-column-method {
      }
      .table-column-type {
      }
      .table-column-url {
      }
      .table-column-option {
        .option-btn-container {
        }
      }
      .table-url-camera-header {
        tr {
          th {
            &.table-column-method {
              padding-left: 20px;
            }
          }
        }
      }
      .table-url-camera-body {
        tr {
          td {
            &.table-column-method {
              padding-left: 20px;
            }
            &.table-column-option {
              .option-btn {
                .option-wrapper {
                }
              }
            }
          }
        }
        .no-data-row-container {
          .no-data-content {
          }
        }
      }
    }
  }
`
