import React from 'react'
import PropTypes from 'prop-types'

import ModalURLSetting from '../ModalURLSetting/dynamic'
import DialogModal from '../DialogModal/dynamic'

import { withTranslation } from '../../../i18n'

import TableURLCameraStyled from './styled'
import EditImg from '../../assets/images/table-url-camera/edit.svg'
import DeleteImg from '../../assets/images/table-url-camera/delete.svg'
import AlertImg from '../../assets/images/dialog-modal/st-wrong.svg'

export class TableURLCamera extends React.PureComponent {
  state = {
    showDeleteWebhookSettingModal: false,
    showURLWebhookSettingModal: false,
    selectedURLWebhookData: undefined,
  }

  getHeaderTable() {
    return (
      <thead className="table-url-camera-header">
        <tr>
          <th className="table-column-method">{this.props.t('method')}</th>
          <th className="table-column-url">{this.props.t('url')}</th>
          <th className="table-column-type">{this.props.t('detection_type')}</th>
          <th className="table-column-option"></th>
        </tr>
      </thead>
    )
  }

  handleEditClicked = selectedURLWebhookData => {
    this.setState({
      selectedURLWebhookData,
      showURLWebhookSettingModal: true,
    })
  }

  handleDeleteClicked = selectedURLWebhookData => {
    this.setState({
      selectedURLWebhookData,
      showDeleteWebhookSettingModal: true,
    })
  }

  getBodyTable() {
    const rows = this.props.listWebhook.map(urlData => {
      const editBtn = (
        <div className="option-btn-container" onClick={() => this.handleEditClicked(urlData)}>
          <img src={EditImg} />
        </div>
      )
      const deleteBtn = (
        <div className="option-btn-container" onClick={() => this.handleDeleteClicked(urlData)}>
          <img src={DeleteImg} />
        </div>
      )
      return (
        <tr key={urlData.id}>
          <td className="table-column-method">{urlData.method}</td>
          <td className="table-column-url">{urlData.url}</td>
          <td className="table-column-type">{urlData.type}</td>
          <td className="table-column-option">
            {editBtn}
            {deleteBtn}
          </td>
        </tr>
      )
    })
    const noData = (
      <tr className="no-data-row-container">
        <td className="no-data-content">{this.props.t('no_data')}</td>
      </tr>
    )
    return <tbody className="table-url-camera-body">{this.props.listWebhook.length > 0 ? rows : noData}</tbody>
  }

  handleCloseURLWebhookModal = () => {
    this.setState({
      showURLWebhookSettingModal: false,
    })
  }

  handleSubmitURLWebhook = () => {
    this.props.onEditWebhook(this.props.cameraData, this.state.selectedURLWebhookData)
    this.handleCloseURLWebhookModal()
  }

  handleURLWebhookDataChanged = (property, value) => {
    this.setState(state => ({
      selectedURLWebhookData: { ...state.selectedURLWebhookData, [property]: value },
    }))
  }

  getURLWebhookSettingModal() {
    let output = null
    if (this.state.showURLWebhookSettingModal) {
      output = (
        <ModalURLSetting
          id="url-setting-modal"
          methodOptions={this.props.methodOptions}
          eventOptions={this.props.eventOptions}
          webhookData={this.state.selectedURLWebhookData}
          onChange={this.handleURLWebhookDataChanged}
          onSubmitClicked={this.handleSubmitURLWebhook}
          onCancelClicked={this.handleCloseURLWebhookModal}
        />
      )
    }
    return output
  }

  handleCancelDeleteWebhookBtnClicked = () => {
    this.setState({
      showDeleteWebhookSettingModal: false,
      selectedURLWebhookData: undefined,
    })
  }

  handleDeleteWebhookBtnClicked = () => {
    this.props.onDeleteWebhook(this.props.cameraData, this.state.selectedURLWebhookData)
    this.handleCancelDeleteWebhookBtnClicked()
  }

  getDeleteURLWebhookModal() {
    let output = null
    if (this.state.showDeleteWebhookSettingModal) {
      output = (
        <DialogModal
          id="delete-webhook-url-dialog-modal"
          imgSrc={AlertImg}
          title={this.props.t('delete_url')}
          content={this.props.t('are_you_sure_to_delete_url')}
          primaryBtnOnClick={this.handleDeleteWebhookBtnClicked}
          primaryBtnText={this.props.t('delete')}
          onOverlayClick={this.handleCancelDeleteWebhookBtnClicked}
        />
      )
    }
    return output
  }

  render() {
    const { tableWrapperClassName } = this.props
    let className = 'table-webhook-container'
    className += ` ${this.props.className}`
    return (
      <TableURLCameraStyled className={tableWrapperClassName}>
        <table className={className}>
          {this.getHeaderTable()}
          {this.getBodyTable()}
        </table>
        {this.getURLWebhookSettingModal()}
        {this.getDeleteURLWebhookModal()}
      </TableURLCameraStyled>
    )
  }
}

TableURLCamera.defaultProps = {
  listWebhook: [],
  className: '',
  tableWrapperClassName: '',
  methodOptions: [],
  eventOptions: [],
}

TableURLCamera.propTypes = {
  className: PropTypes.string,
  tableWrapperClassName: PropTypes.string,
  methodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    }),
  ),
  eventOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    }),
  ),
  cameraData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    zoneId: PropTypes.number.isRequired,
  }).isRequired,
  listWebhook: PropTypes.arrayOf(
    PropTypes.shape({
      method: PropTypes.oneOf(['POST', 'GET']).isRequired,
      url: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  onEditWebhook: PropTypes.func.isRequired,
  onDeleteWebhook: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default withTranslation()(TableURLCamera)
